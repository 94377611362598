import { Container, InnerContainer } from "./App.styles";
import { GlobalStyle } from "./styles/global";
import { Form } from "./components/Form/Form";

function App() {
  return (
    <>
      <GlobalStyle />
      <Container>
        <InnerContainer>
          <h1>KCSOC 2022/23 Committee Registration Form</h1>
          <h3>
            Please fill out the form below as you’re now part of the KCSOC
            Family 2022/23 🥳
          </h3>
          <Form />
        </InnerContainer>
      </Container>
    </>
  );
}

export default App;
