import styled from "styled-components";

export const Container = styled.div`
  background: linear-gradient(#9de7f5, #9198e5, #edb4ce);
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
`;

export const InnerContainer = styled.div`
  max-width: 1400px;
  margin: 50px auto;
  padding: 50px 30px;
  text-align: center;

  // Mobile Styling
  @media screen and (max-width: 500px) {
    margin: 20px auto;
    h1 {
      font-size: 25px;
    }

    h3 {
      font-size: 16px;
    }
  }
`;
