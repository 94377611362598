import { transformDate } from "../utils/transformDate";

export const onSubmit = ({
  firstName,
  lastName,
  dateOfBirth,
  outOfTermLocation,
  university,
  subject,
  phone,
  email,
  graduationYear,
  position,
  somethingInteresting,
  setSlideNumber,
  setRequestStatus,
}: any) => {
  const formdata = new FormData();
  formdata.append("First Name", firstName);
  formdata.append("Last Name", lastName);
  formdata.append("Date of Birth", transformDate(dateOfBirth));
  formdata.append("Out of term location", outOfTermLocation);
  formdata.append("Subject", subject);
  formdata.append("University", university);
  formdata.append("Phone", phone);
  formdata.append("Email", email);
  formdata.append("Year of Graduation", graduationYear);
  formdata.append("Committee Position", position);
  formdata.append("Something interesting", somethingInteresting);

  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  } as any;

  setSlideNumber(12);
  setRequestStatus("waiting");

  fetch(
    "https://script.google.com/macros/s/AKfycbxvGrfhtASM3JuC62Y1ewBSJKasmmrPjILx5Ben5y0vVP025UWxDTfYtSz7zfsiqOopnw/exec",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
      setRequestStatus("success");
    })
    .catch((error) => {
      console.log("error", error);
      setRequestStatus("failure");
    });
};
