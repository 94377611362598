import { useState } from "react";
import {
  BsFillArrowRightSquareFill,
  BsFillArrowLeftSquareFill,
} from "react-icons/bs";
import {
  Container,
  SlideContainer,
  Slide,
  ArrowContainer,
  Question,
  Submit,
  ArrowFiller,
  FinalSlide,
  SummaryContainer,
} from "./Form.styles";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { onSubmit } from "../../handers/onSubmit";
import { transformDate } from "../../utils/transformDate";

import unis from "../../assets/unis.json";

export const Form = () => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [requestStatus, setRequestStatus] = useState("");

  // Form state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("2000-01-01");
  const [outOfTermLocation, setOutOfTermLocation] = useState("");
  const [university, setUniversity] = useState("");
  const [subject, setSubject] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [graduationYear, setGraduationYear] = useState("");
  const [position, setPosition] = useState("");
  const [somethingInteresting, setSomethingInteresting] = useState("");

  const availableSlides = calculateAvailableSlides({
    firstName,
    lastName,
    dateOfBirth,
    outOfTermLocation,
    university,
    subject,
    phone,
    email,
    graduationYear,
    position,
    somethingInteresting,
  });

  return (
    <Container>
      <SlideContainer slideNumber={slideNumber}>
        <Slide>
          <Question>Question 1 / 10</Question>
          <Question>What is your first name?</Question>
          <Input
            style={{ width: "100%" }}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <p>I promise, the questions are not going to be that hard...</p>
        </Slide>
        <Slide>
          <Question>Question 2 / 10</Question>
          <Question>What is your last name?</Question>
          <Input
            style={{ width: "100%" }}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <p>See... not that hard... I hope you know the answer!</p>
        </Slide>
        <Slide>
          <Question>Question 3 / 10</Question>
          <Question>What is your date of birth?</Question>
          <TextField
            id="date"
            label="Birthday"
            type="date"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <p>
            We want to know the age of your body - ikyk the soul is eternal 🤯{" "}
          </p>
        </Slide>
        <Slide>
          <Question>Question 4 / 10</Question>
          <Question>
            Outside of term time, which town/city do you live in?
          </Question>
          <Input
            style={{ width: "100%" }}
            value={outOfTermLocation}
            onChange={(e) => setOutOfTermLocation(e.target.value)}
          />
          <p>Keep in touch with KCSOC outside of term too! 🔥</p>
        </Slide>
        <Slide>
          <Question>Question 5 / 10</Question>
          <Question>What university do you go to (or graduated from)?</Question>
          <Select
            value={university}
            label="University"
            placeholder="Choose your university"
            onChange={(e) => setUniversity(e.target.value)}
            style={{ width: "100%" }}
          >
            {unis.map((uni, index) => (
              <MenuItem key={index} value={uni}>
                {uni}
              </MenuItem>
            ))}
          </Select>
          <p>We need a KCSOC at each of these universities! 💪</p>
        </Slide>
        <Slide>
          <Question>Question 6 / 10</Question>
          <Question>
            What subject do you study (or what is your occupation)?
          </Question>
          <Input
            style={{ width: "100%" }}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <p>Feel free to geek out 🤓</p>
        </Slide>
        <Slide>
          <Question>Question 7 / 10</Question>
          <Question>What is your phone number?</Question>
          <Input
            style={{ width: "100%" }}
            value={phone}
            type="number"
            onChange={(e) => setPhone(e.target.value)}
          />
          <p>
            We'll add you to the BRAND NEW KCSOC National 2022/23 group chat on
            Whatsapp!
          </p>
        </Slide>
        <Slide>
          <Question>Question 8 / 10</Question>
          <Question>What is your email?</Question>
          <Input
            style={{ width: "100%" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Slide>
        <Slide>
          <Question>Question 9 / 10</Question>
          <Question>What year do you graduate?</Question>
          <Select
            value={graduationYear}
            label="Year of Graduation"
            onChange={(e) => setGraduationYear(e.target.value)}
            style={{ width: "100%" }}
          >
            <MenuItem value={"2022"}>2022</MenuItem>
            <MenuItem value={"2023"}>2023</MenuItem>
            <MenuItem value={"2024"}>2024</MenuItem>
            <MenuItem value={"2025"}>2025</MenuItem>
            <MenuItem value={"2026 or later"}>2026 or later</MenuItem>
            <MenuItem value={"Already graduated"}>Already graduated</MenuItem>
          </Select>
        </Slide>
        <Slide>
          <Question>Question 10 / 10</Question>
          <Question>What is your position on the KCSOC committee?</Question>
          <Input
            style={{ width: "100%" }}
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
          <p>Slap it on your CV while you're at it!</p>
        </Slide>
        <Slide>
          <Question>Bonus Question</Question>
          <Question>Tell us something interesting about yourself...</Question>
          <Input
            style={{ width: "100%" }}
            value={somethingInteresting}
            onChange={(e) => setSomethingInteresting(e.target.value)}
          />
          <p>Come on! You can think of something...</p>
        </Slide>
        <Slide>
          <SummaryContainer>
            <Question>Summary</Question>
            <p>First Name: {firstName}</p>
            <p>Last Name: {lastName}</p>
            <p>Date of Birth: {transformDate(dateOfBirth)}</p>
            <p>Out of term location: {outOfTermLocation}</p>
            <p>University: {university}</p>
            <p>Subject: {subject}</p>
            <p>Phone: {phone}</p>
            <p>Email: {email}</p>
            <p>Year of Graduation: {graduationYear}</p>
            <p>Committee Position: {position}</p>
            <p>Something interesting about you: {somethingInteresting}</p>
          </SummaryContainer>
          <p>You may need to scroll to see everything</p>
        </Slide>
        <FinalSlide>
          {requestStatus === "waiting" && <CircularProgress />}
          {requestStatus === "success" && (
            <>
              <Question>Congratulations!</Question>
              <Question>Your form was submitted successfully! 🥳</Question>
              <p>If you made a mistake, you can submit the form again</p>
            </>
          )}
          {requestStatus === "failure" && (
            <>
              <Question>Uh oh, there was some error 😟</Question>
              <p>Hmm... maybe a techincal issue. Check your internet.</p>
              <p> If the error persists, reach out to Josh or Niraj</p>
            </>
          )}
        </FinalSlide>
      </SlideContainer>
      <ArrowContainer>
        {slideNumber === 11 ? (
          <Submit
            onClick={() =>
              onSubmit({
                firstName,
                lastName,
                dateOfBirth,
                outOfTermLocation,
                university,
                subject,
                phone,
                email,
                graduationYear,
                position,
                somethingInteresting,
                setSlideNumber,
                setRequestStatus,
              })
            }
          >
            Submit
          </Submit>
        ) : (
          <ArrowFiller />
        )}
        {slideNumber ? (
          <BsFillArrowLeftSquareFill
            fontSize={40}
            onClick={() => setSlideNumber((prev) => prev - 1)}
          />
        ) : (
          <BsFillArrowLeftSquareFill
            fontSize={40}
            style={{ color: "#888", cursor: "not-allowed" }}
          />
        )}

        {slideNumber < availableSlides ? (
          <BsFillArrowRightSquareFill
            fontSize={40}
            onClick={() => setSlideNumber((prev) => prev + 1)}
          />
        ) : (
          <BsFillArrowRightSquareFill
            fontSize={40}
            style={{ color: "#888", cursor: "not-allowed" }}
          />
        )}
      </ArrowContainer>
    </Container>
  );
};

function calculateAvailableSlides({
  firstName,
  lastName,
  dateOfBirth,
  outOfTermLocation,
  university,
  subject,
  phone,
  email,
  graduationYear,
  position,
  somethingInteresting,
}: {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  outOfTermLocation: string;
  university: string;
  subject: string;
  phone: string;
  email: string;
  graduationYear: string;
  position: string;
  somethingInteresting: string;
}) {
  if (!firstName) return 0;
  if (!lastName) return 1;
  if (!dateOfBirth) return 2;
  if (!outOfTermLocation) return 3;
  if (!university) return 4;
  if (!subject) return 5;
  if (!phone) return 6;
  if (!email) return 7;
  if (!graduationYear) return 8;
  if (!position) return 9;
  if (!somethingInteresting) return 10;
  return 11;
}
