import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: min(1000px, 90vw);
  margin: 50px 0;
  min-height: 500px;
  overflow: hidden;

  position: relative;
  text-align: left;

  // Mobile Styling
  @media screen and (max-width: 500px) {
    margin: 50px auto;
    max-width: 80vw;
    min-height: 150px;
    max-height: 450px;
  }
`;

export const SlideContainer = styled.div<{ slideNumber: number }>`
  display: flex;
  transform: translateX(${({ slideNumber }) => -(slideNumber * 100) + "%"});
  transition: all 300ms ease-in-out;
`;

export const Slide = styled.div`
  box-sizing: border-box;
  min-width: 100%;
  padding: 30px;

  // Mobile Styling
  @media screen and (max-width: 500px) {
    max-height: 450px;
  }
`;

export const FinalSlide = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding: 30px;
  justify-content: center;
  text-align: center;
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-around;
  bottom: 10px;
  right: 20px;
  position: absolute;
  width: 200px;

  svg {
    cursor: pointer;
  }
`;

export const ArrowFiller = styled.div`
  width: 63px;
`;

export const Question = styled.h2`
  margin-bottom: 30px;
`;

export const Submit = styled.button`
  outline: none;
  background: none;
  border: 1px solid #222;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
`;

export const SummaryContainer = styled.div`
  max-height: 70%;
  margin-bottom: 30px;
  overflow: scroll;
`;
